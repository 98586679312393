
import { defineComponent, useContext, useFetch } from '@nuxtjs/composition-api'
import { CacheTagPrefix, useCache } from '@zaprooecommerce/vsf-cache'
import { StrapiContentSection } from '@zaprooecommerce/strapi'
import { MetaBuilder } from '~/helpers/meta/MetaBuilder'
import { useStrapiSingleType } from '~/modules/strapi/composables'
import LazyFor from '~/components/LazyFor.vue'
import PageLazyHydrate from '~/components/PageLazyHedrate.vue'

export default defineComponent({
  name: 'HomePage',
  components: {
    PageLazyHydrate,
    LazyFor,
    StrapiContentSection
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup () {
    const { addTags, addCacheTtl } = useCache()
    const { $config } = useContext()
    const { storeURL } = $config
    const { load: loadSingleType, sections } = useStrapiSingleType('home-page')

    useFetch(async (): Promise<void> => {
      await loadSingleType()
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }])
      addCacheTtl('1h', '5s')
    })

    return {
      storeURL,
      sections
    }
  },
  head () {
    const builder = new MetaBuilder()
    const title: string = this.$i18n.t('HOME_PAGE_TITLE') as string
    const description: string = this.$i18n.t('HOME_PAGE_DESCRIPTION') as string
    const baseURL: string = this.storeURL + this.$route.fullPath as string

    return builder
      .useTitle(title)
      .useDescription(description)
      .useRobots(true, true)
      .useOpenGraph({
        title,
        description,
        url: baseURL,
        image: this.storeURL + '/logo/logo_xl.png'
      }).build()
  }
})
