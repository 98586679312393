
import LazyHydrate from 'vue-lazy-hydration'
import { computed, defineComponent, onMounted, PropType, ref, toRef, watch } from '@nuxtjs/composition-api'
import { useUser } from '~/composables'

export default defineComponent({
  name: 'PageLazyHydrate',
  components: {
    LazyHydrate
  },
  props: {
    hydrateTrigger: {
      type: Boolean as PropType<boolean>,
      default: () => false
    },
    hydrateTimeout: {
      type: Number as PropType<number>,
      default: () => 10000
    }
  },
  setup (props) {
    const { isAuthenticated } = useUser()
    const hydrateTrigger = toRef(props, 'hydrateTrigger')
    const routeHydrationTrigger = ref(false)
    const commonHydrationTrigger = computed(() => isAuthenticated.value || routeHydrationTrigger.value || hydrateTrigger.value)
    let timeoutId: ReturnType<typeof setTimeout> | undefined

    onMounted(() => {
      document.addEventListener('scroll', rootTriggerListener)
      document.body.addEventListener('mouseenter', rootTriggerListener)
      document.body.addEventListener('touchstart', rootTriggerListener)
      document.body.addEventListener('click', rootTriggerListener)

      if (process.client) {
        timeoutId = setTimeout(() => {
          rootTriggerListener()
        }, props.hydrateTimeout)
      }
    })

    watch(commonHydrationTrigger, (val:boolean) => {
      if (val) {
        unsubscribe()
      }
    })

    function rootTriggerListener ():void {
      routeHydrationTrigger.value = true
      unsubscribe()
    }

    function unsubscribe ():void {
      clearTimeout(timeoutId)
      document.removeEventListener('scroll', rootTriggerListener)
      document.body.removeEventListener('mouseenter', rootTriggerListener)
      document.body.removeEventListener('touchstart', rootTriggerListener)
      document.body.removeEventListener('click', rootTriggerListener)
    }

    return {
      commonHydrationTrigger
    }
  }
})
