import { Meta } from '~/helpers/meta/Meta'
import { DefineMeta } from '~/helpers/meta/DefineMeta'
import { DefineOpenGraph } from '~/helpers/meta/DefineOpenGraph'

export class MetaBuilder {
  #meta: Meta = new Meta()

  /**
   * Sets title for the page
   * @param title Used title
   */
  useTitle (title: string): MetaBuilder {
    this.#meta.title = title + ' - Aatrium'
    this.useMeta('title', title)
    return this
  }

  /**
   * Sets meta description
   * @param description Used description
   */
  useDescription (description: string): MetaBuilder {
    this.#meta.defineMeta({ name: 'description', hid: 'description', content: description })
    return this
  }

  /**
   * Defines or overrides a meta property
   * @param nameOrOptions Meta property name or meta object
   * @param content Meta content if name was used as a first parameter
   */
  useMeta (nameOrOptions: DefineMeta | string, content?: string): MetaBuilder {
    if (Object.getPrototypeOf(nameOrOptions) === Object.prototype) {
      this.#meta.defineMeta(nameOrOptions as DefineMeta)
    } else {
      this.#meta.defineMeta({ content, name: nameOrOptions as string })
    }
    return this
  }

  useName (content: string): MetaBuilder {
    return this.useMeta({ itemprop: 'name', content })
  }

  useImage (content: string): MetaBuilder {
    return this.useMeta({ itemprop: 'image', content })
  }

  useRobots (allowIndex: boolean, allowFollow: boolean): MetaBuilder {
    return this.useMeta(
      'robots',
      `${allowIndex ? 'index' : 'noindex'}, ${allowFollow ? 'follow' : 'nofollow'}`
    )
  }

  useOpenGraph (content: DefineOpenGraph): MetaBuilder {
    content.site_name = content.site_name || 'Aatrium'
    content.title = content.title + ' - Aatrium'
    Object.entries(content).forEach(([key, value]) => {
      if (value) {
        this.useMeta({
          name: `og:${key}`,
          property: `og:${key}`,
          hid: `og:${key}`,
          content: value
        })
      }
    })
    return this
  }

  /**
   * Creates meta object instance
   */
  build (): Meta {
    const builtMeta = this.#meta
    this.#meta = new Meta()
    return builtMeta
  }
}
