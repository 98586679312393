import { MetaInfo } from 'vue-meta/types/vue-meta'
import { DefineMeta } from '~/helpers/meta/DefineMeta'

export class Meta implements MetaInfo {
  title: string = null
  htmlAttrs = {}
  link = []
  meta: DefineMeta[] = []

  defineMeta (options: DefineMeta): void {
    const {
      hid = null,
      name = null,
      itemprop = null,
      property = null,
      content
    } = options

    this.meta.push({
      ...(hid && { hid }),
      ...(name && { name }),
      ...(itemprop && { itemprop }),
      ...(property && { property }),
      content
    })
  }
}
