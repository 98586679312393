
import { defineComponent, computed, h, PropType } from '@nuxtjs/composition-api'
import type { Component, VNode } from 'vue'

export default defineComponent({
  name: 'LazyFor',
  props: {
    items: {
      type: Array as PropType<Array<unknown>>,
      default: () => []
    },
    visibleCount: {
      type: Number,
      default: () => 5
    },
    wrapper: {
      type: [Object, String] as PropType<Component | string>,
      default: () => 'div'
    }
  },
  setup (props, { slots, attrs, listeners }) {
    const visibleItems = computed<unknown[]>(() => props.items.slice(0, props.visibleCount) || [])
    const otherItems = computed<unknown[]>(() => props.items.slice(props.visibleCount) || [])

    function renderItem (item: unknown, index: number, scope: 'vis' | 'hid'): VNode[] {
      index = (scope === 'hid') ? index + props.visibleCount : index
      const items = slots.default({ item, index })
      items.forEach((item) => {
        item.key = `${scope}_${index}`
      })

      return items
    }

    return () => h(props.wrapper, {
      attrs, on: listeners
    }, [
      ...(visibleItems.value.map((item, index) => renderItem(item, index, 'vis'))),
      h('client-only', otherItems.value.map((item, index) => renderItem(item, index, 'hid')))

    ])
  }

})

