import { ref } from '@nuxtjs/composition-api'
import { useStrapi } from '~/modules/strapi/composables/useStrapi'
import { AatriumStrapiEntity, AatriumStrapiSection, UseStrapiSingleType } from '~/modules/strapi/types'

export const useStrapiSingleType = (entity: string, field: string = 'sections'): UseStrapiSingleType => {
  const strapi = useStrapi()

  const entityPage = ref<AatriumStrapiEntity>(null)
  const sections = ref<AatriumStrapiSection[]>([])

  const load = async (): Promise<void> => {
    entityPage.value = await strapi.find<AatriumStrapiEntity>(entity)
    sections.value = entityPage.value[field]
  }

  return {
    load,
    page: entityPage,
    sections
  }
}
